import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'gatsby'

import ProductCard from '../ProductCard/ProductCard'

import GiftVoucher25 from '../../images/svgs/gift-voucher-25.svg'
import MakeoverVoucher from '../../images/svgs/makeover-voucher.svg'

export default function ProductGrid( props ) {

	return (
		<>
			<div className="product-grid">
				<div className="product-grid__wrapper">
					{ props.isGifts &&
						<>
							<div className="product-card">
								<div className="product-card__container">
									<Link
										to="/products/gift-vouchers"
										className="product-card__item-link product-card__item-link--gift">
										<div className="product-card__item-image-container">
											<GiftVoucher25 className="product-card__item-image" />
										</div>
										<div className="product-card__item-meta-wrapper">
											<h3 className="product-card__item-name">Gift vouchers</h3>
										</div>
									</Link>
								</div>
							</div>
							<div className="product-card">
								<div className="product-card__container">
									<Link
										to="/products/makeover-day-vouchers"
										className="product-card__item-link product-card__item-link--gift">
										<div className="product-card__item-image-container">
											<MakeoverVoucher className="product-card__item-image" />
										</div>
										<div className="product-card__item-meta-wrapper">
											<h3 className="product-card__item-name">Makeover day vouchers</h3>
										</div>
									</Link>
								</div>
							</div>
						</>
					}
					{ props.products.map( ( node, index ) => (
						<React.Fragment key={ node.id }>
							<ProductCard product={ node } index={ index } setCount={ props.setCount } count={ props.count } />
						</React.Fragment>
					) ) }
				</div>
			</div>
		</>
	)
}

ProductGrid.propTypes = {
	products: PropTypes.array
};

import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import FilterListItem from './FilterListItem'

const PRICE_MAP = [
	{ label: 'Up to £20', lowestPrice: 0, highestPrice: 20 },
	{ label: 'Up to £50', lowestPrice: 0, highestPrice: 50 },
	{ label: 'Up to £75', lowestPrice: 0, highestPrice: 75 },
	{ label: 'Up to £100', lowestPrice: 0, highestPrice: 100 },
	{ label: '£100+', lowestPrice: 100, highestPrice: 1000 },
]

export default function FilterList ( props ) {

	const data = useStaticQuery(graphql`
		query getFilters {
			strapi {
				colourFilters(sort: "colour:ASC") {
					id
					colour
				}
				brands(sort: "name:ASC") {
					id
					name
				}
				sizes(sort: "size:ASC") {
					size
					id
				}
			}
		}
	`)

	const { filterState, setFilterState, subCategories } = props
	const [ activePrice, setActivePrice ] = useState()

	const brands = data.strapi.brands
	const colours = data.strapi.colourFilters
	const sizes = data.strapi.sizes

	const handlePriceFilter = ( price ) => {

		if ( price.label !== activePrice ) {
			setActivePrice( price.label )
		} else {
			setActivePrice()
		}

		const updatedPriceRange = updatePriceRange( filterState, price )

		setFilterState( state => ({
			...state,
			...updatedPriceRange
		}) )
	}

	const updatePriceRange = ( state, price ) => {

		// update the less than price, or remove filter if the same option is clicked
		if ( price.label !== activePrice ) {
			state.price_lte = [ price.highestPrice ]
		} else {
			state.price_lte = []
		}

		// As there is only one option that is above a certain amount
		if ( price.lowestPrice && price.label !== activePrice ) {
			state.price_gte = [ price.lowestPrice ]
		} else {
			state.price_gte = []
		}

		return state
	}

	useEffect(() => {

		if ( filterState[ 'price_lte' ] ) {
			const value = filterState[ 'price_lte' ][ 0 ]
			const foundPrice = PRICE_MAP.find( priceObject => value === priceObject.highestPrice )

			if ( foundPrice ) {
				setActivePrice( foundPrice.label )
			}
		}

		if ( filterState[ 'price_gte' ] ) {
			const value = filterState[ 'price_gte' ][ 0 ]
			const foundPrice = PRICE_MAP.find( priceObject => value === priceObject.lowestPrice )

			if ( foundPrice ) {
				setActivePrice( foundPrice.label )
			}
		}
	}, [ filterState, setActivePrice ])

	return (
		<div className="filters__filters-wrapper">
			{ subCategories &&
				<div className="filters__filter-section">
					<div className="filters__filter-type">Category</div>
					<ul className="filters__filter-list">
						{ subCategories.map( category => (
							<FilterListItem
								key={ category.id }
								label={ category.label }
								id={ category.id }
								filterType={ 'sub_category' }
								setFilterState={ setFilterState }
								filterState={ filterState } />
						) ) }
					</ul>
				</div>
			}
			<div className="filters__filter-section">
				<div className="filters__filter-type">Size</div>
				<ul className="filters__filter-list">
					{ sizes.map( size => (
						<FilterListItem
						key={ size.id }
						label={ size.size }
						id={ size.id }
						filterType={ 'sizes' }
						nestedFilter={ 'id' }
						setFilterState={ setFilterState }
						filterState={ filterState } />
					) ) }
				</ul>
			</div>
			<div className="filters__filter-section">
				<div className="filters__filter-type">Colour</div>
				<ul className="filters__filter-list">
					{ colours.map( colour => (
						<FilterListItem
							key={ colour.id }
							label={ colour.colour }
							id={ colour.id }
							filterType={ 'colour_option' }
							nestedFilter={ 'colour_category' }
							setFilterState={ setFilterState }
							filterState={ filterState } />
					) ) }
				</ul>
			</div>
			<div className="filters__filter-section">
				<div className="filters__filter-type">Brand</div>
				<ul className="filters__filter-list">
					{ brands.map( brand => (
						<FilterListItem
							key={ brand.id }
							label={ brand.name }
							id={ brand.id }
							filterType={ 'brand' }
							setFilterState={ setFilterState }
							filterState={ filterState } />
					) ) }
				</ul>
			</div>
			<div className="filters__filter-section">
				<div className="filters__filter-type">Price</div>
				<ul className="filters__filter-list">
					{ PRICE_MAP.map( ( price, i ) => (
						<li key={ i } className={`filters__filter-item ${ activePrice === price.label ? 'active' : '' }`}
							>
							<button className="filters__filter-item-button" onClick={ () => handlePriceFilter( price ) }>{ price.label }</button>
						</li>
					) ) }
				</ul>
			</div>
		</div>
	)
}
/**
 * Takes a string and replaces the '|' pipes with highlight markup
 *
 * @param {String} string String that contains '|" characters to be formated
 * @return {String} replaces piped text with highlight markup
 */
export function formatTitle( string ) {

	const MAX_PIPES = 2;
	const STRING_START_INDEX = 0;
	const STRING_HIGHLIGHT_INDEX = 1;
	const STRING_END_INDEX = 2;
	
	const regex = /\|/g;
	const match = string.match( regex );
	let cleanString = string;

	// if no pipes or more than two pipes, return initial string
	if ( !match || match.length > MAX_PIPES ) { return { __html: string }; }

	// If only one pipeline, add a pipe at the end
	if ( match.length === 1 ) {
		cleanString = `${cleanString}|`;
	}

	// Split string based on pipes
	const splitString = cleanString.split( regex );

	// Wrap text that was between pipes in the highlight markup
	const highlight = `<span class="highlight">${ splitString[ STRING_HIGHLIGHT_INDEX ] }</span>`;
	
	// Concatenate highlight with start of string
	const highlightString = `${ splitString[ STRING_START_INDEX ] }${ highlight }${ splitString[ STRING_END_INDEX ] }`;

	return { __html: highlightString }; // this object format is used to parse HTML in React
}
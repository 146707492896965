import React, { useEffect, useState } from 'react'

export default function FilterListItem( props ) {

	const { label, id, filterType, filterState, setFilterState, nestedFilter } = props
	const [ active, setActive ] = useState( false )

	// If a quick filter is selected, show item as selected
	useEffect(() => {
		if ( filterState[ filterType ] && filterType === 'sub_category' ) {
			const currentSelections = filterState[ filterType ]
			const foundId = currentSelections.find( filterId => id === filterId )

			if ( foundId ) {
				setActive( true )
			} else {
				setActive( false )
			}
		} else if ( filterState[ filterType ] ) {
			let currentSelections

			if ( nestedFilter ) {
				currentSelections = filterState[ filterType ][ nestedFilter ]
			} else {
				currentSelections = filterState[ filterType ]
			}

			const foundId = currentSelections.find( filterId => id === filterId )

			if ( foundId ) {
				setActive( true )
			} else {
				setActive( false )
			}
		}
	}, [ filterState, filterType, id ])

	const toggleFilter = ( state, filterId ) => {

		let currentSelections

		if ( nestedFilter ) {
			currentSelections = state[ filterType ][ nestedFilter ]
		} else {
			currentSelections = state[ filterType ]
		}
			
		const foundId = currentSelections.find( id => filterId === id )

		// if it doesn't exist, push to array
		if ( !foundId ) {
			currentSelections.push( filterId )
		}

		if ( foundId && active ) {
			const index = currentSelections.indexOf( filterId )
			if ( index > -1 ) { currentSelections.splice( index, 1 ) }
		}

		return state
	}

	const handleOnClick = ( filterId ) => {

		setActive( !active )
		const updatedFilters = toggleFilter( filterState, filterId )

		setFilterState( state => ({
			...state,
			...updatedFilters
		}) )
	}

	return (
		<li className={`filters__filter-item ${ active ? 'active' : ''}`}>
			<button className="filters__filter-item-button" onClick={ () => handleOnClick( id ) }>
				{ label }
				{ active &&
					<span className="filters__filter-item-close">x</span>
				}
			</button>
		</li>
	)
}
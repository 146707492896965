import React, { useEffect, useState, useRef, useContext } from 'react'
import { useQuery, gql } from '@apollo/client';

import ProductGrid from '../ProductGrid/ProductGrid'
import Filters from "../Filters/Filters"

import { getFluidImage } from '../../helpers'

import { FaSpinner } from 'react-icons/fa'

import { CategoryContext } from '../../context/CategoryContext'

export default function ProductsFilter ( props ) {

	const { subCategories, subCatgorySelected, mainCategoryId, isGifts } = props
	const { updateCategory, category, globalSort } = useContext( CategoryContext )

	const infiniteRef = useRef()

	const maxWidth = 500
	const reloadlimit = 12
	const [ sort, setSort ] = useState( globalSort.sort )
	const [ products, setProducts ] = useState( [] )

	const [ infiniteScroll, setInfiniteScroll ] = useState( true )
	const [ loading, setLoading ] = useState( true )
	const [ count, setCount ] = useState()
	const [ limit, setLimit ] = useState( reloadlimit )
	const [ button, setButton ] = useState( true )
	const [ filterState, setFilterState ] = useState({
		main_categories: {
			id: [ mainCategoryId ]
		},
		sub_category: [],
		brand: [],
		colour_option: {
			colour_category: []
		},
		sizes: {
			id: []
		},
		price_lte: [],
		price_gte: []
	})

	let variables = { sort: sort, limit: limit, where: filterState }

	useEffect(() => {

		let isMounted = true // note this flag denote mount status

		if ( isMounted ) {
			updateCategory( { ...category, [ mainCategoryId ] : { state: filterState, count: count } } )
			window.scrollTo(0, 0)
		}

		return () => { isMounted = false } // use effect cleanup to set flag false, if unmounted
	
	}, [ filterState, count ])

	useEffect(() => {
		if ( category[ mainCategoryId ] ) {
			setFilterState( category[ mainCategoryId ].state )

			if ( category[ mainCategoryId ].count ) {
				setCount( category[ mainCategoryId ].count )
				setLimit( Math.ceil( category[ mainCategoryId ].count / reloadlimit ) * reloadlimit )
			}
		}
	}, [ mainCategoryId ] )

	useEffect(() => {

		if ( subCatgorySelected ) {
			setFilterState( state => ({
				...state,
				sub_category: [ subCatgorySelected ]
			}) )
		}

	}, [ subCatgorySelected ] )

	useEffect( () => {

		// Detect when scrolled to bottom.
		const scrollFunction = () => {
			let position
			if ( infiniteRef.current ) position = infiniteRef.current.getBoundingClientRect()
				
				if ( infiniteScroll && position &&
					( position.top - 30 ) < window.innerHeight && position.bottom >= 0
				) {
					setInfiniteScroll( false )
					handleLoadMore();
			}
		};

		window.addEventListener( 'scroll', scrollFunction )

		return () => {
			// remove resize listener
			window.removeEventListener( 'scroll', scrollFunction );
		};
	}, [ infiniteScroll ])

	const productQuery = gql`
		query getProducts ($start: Int, $limit: Int, $sort: String, $where: JSON) {
			products ( start: $start, limit: $limit, sort: $sort, where: $where) {
				id
				name
				price
				slug
				includeInSale
				sale {
					discount
					amount
				}
				images {
					url
					formats
					width
					height
				}
				colour_option {
					hexValue
					name
					id
					colour_category {
						id
						colour
					}
				}
				relatedProducts {
					products {
						id
						slug
						colour_option {
							hexValue
							id
							name
						}
					}
				}
			}
		}
	`;

	let { error, data, refetch, fetchMore } = useQuery( productQuery, {
		variables: {
			start: 0,
			...variables
		},
		fetchPolicy: "cache-and-network"
	} );

	const renderProductImages = ( products ) => {
		const updatedProducts = products.map( product => {
			product.images[0].imageFile =  { childImageSharp : { fluid : getFluidImage( product, maxWidth ) } }
			return product
		} )

		return updatedProducts
	}

	useEffect(() => {
		refetch()
	}, [ filterState, refetch ])

	useEffect(()=> {

		if ( data ) {
			const updatedProducts = renderProductImages( data.products )
			setProducts( updatedProducts )

			if ( data.products.length < reloadlimit ) {
				setButton( false )
			} else {
				setButton(true)
			}
			setInfiniteScroll( false )
			setLoading( false )
		}
	}, [ data ])

	useEffect(() => {
		const count = category && category[ mainCategoryId ] ? category[ mainCategoryId ].count : 0
		if ( !count ) {
			setInfiniteScroll( true )
		}
	}, [ products, category, mainCategoryId ])

	if ( data ) {
		data = JSON.parse( JSON.stringify( data ) )
	}

	if ( error ) return <p>Error :(</p>

	const handleLoadMore = () => {
		// used for the pagination
		let current = products.length

		setLoading( true )

		fetchMore( {
			variables: {
				start: current,
				limit: reloadlimit
			},
			updateQuery: ( prev, { fetchMoreResult } ) => {

				if ( !fetchMoreResult.products.length ) {
					setButton( false )
					setLoading( false )
					return prev;
				}

				const newFetchMoreResults = JSON.parse( JSON.stringify( fetchMoreResult.products ) )
				const newProducts = renderProductImages( newFetchMoreResults )
				const updatedProducts = [ ...products, ...newProducts ]
				setProducts( updatedProducts )

				if ( fetchMoreResult.products.length < reloadlimit ) {
					setButton( false )
				}

				setLoading( false )
				setInfiniteScroll( true )

				return
			}
		} )
	}

	return (
		<>
			<Filters setSort={ setSort } filterState={ filterState } setFilterState={ setFilterState } subCategories={ subCategories } />

			{ products.length || isGifts ? (
				<ProductGrid products={ products } loading={ loading } isGifts={ isGifts } setCount={ setCount } count={ count } />
			) : (
				<>
					{ !loading &&
						<div className="product-grid__no-products">
							Sorry no products were found matching your selection. Please adjust the filters above to widen your search.
						</div>
					}
				</>
			) }

			{ loading ? (
				<div className="product-grid__loading-container">
					<h3 className="product-grid__loading-text">Fetching products</h3>
					<FaSpinner className="loader" />
				</div>
			) : (
				<button className={`product-grid__load-more ${ button ? '' : 'disabled' }`}
					onClick={ () => { handleLoadMore() } } ref={ infiniteRef }>
						Load more
				</button>
			) }
		</>
	)
}
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import ProductsFilter from '../components/ProductsFilter/ProductsFilter'
import SEO from '../components/SEO/Seo'

const CategoryTemplate = ( { data: { strapi: { mainCategory } }, location } ) => {

	const { pageDescription, sub_categories, quickFilters, pageTitle, id } = mainCategory

	const [ subCatgorySelected, setSubCatgorySelected ] = useState()
	const metaDescription = pageDescription.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 170)
	const isGifts = location.pathname.includes('gift')

	useEffect(() => {
		if ( location && location.state && location.state.categoryId ) {
			setSubCatgorySelected( location.state.categoryId )
		}
	}, [ location ] )

	return (
		<>
			<SEO
				title={ pageTitle }
				description={ metaDescription }
				pathname={ location.pathname } />

			<div className="category-template wrapper">

				<div className="category-template__header">
					<h1 className="category-template__heading">{ pageTitle }</h1>
					<span className="category-template__description">{ pageDescription }</span>
				</div>

				<div className="category-template__quick-filters">
					<ul className="category-template__quick-filters-list">
						{ quickFilters.map( ( category, index ) => {
							// safety check to make sure the sub category is also selected
							const foundCategory = sub_categories.find( item => item.id === category.sub_category.id )
							if ( index < 4 && foundCategory ) {
								return (
									<li className="category-template__quick-filter" key={ category.sub_category.id }>
										<button className="category-template__quick-filter-label" onClick={ () => setSubCatgorySelected( category.sub_category.id ) }>{ category.sub_category.label }</button>
									</li>
								)
							}
							return null
						} ) }
					</ul>
				</div>

				<ProductsFilter
					subCategories={ sub_categories }
					subCatgorySelected={ subCatgorySelected }
					mainCategoryId={ id }
					isGifts={ isGifts } />
			</div>
		</>
	)
}

export default CategoryTemplate

export const query = graphql`
	query categoryQuery($id: ID!) {
		strapi {
			mainCategory( id: $id ) {
				name
				id
				slug
				pageTitle
				pageDescription
				sub_categories {
					id
					label
				}
				quickFilters {
					id
					sub_category {
						label
						id
					}
				}
			}
		}
	}
`
import React, { useState, useEffect, useRef, useContext } from 'react'

import FilterList from './FilterList'

import { MEASUREMENTS } from '../../helpers'
import { CategoryContext } from '../../context/CategoryContext'

import Chevron from '../../images/svgs/chevron.svg';

export default ( props ) => {

	const RESIZE_DEBOUNCE = 150;
	const filtersRef = useRef()

	const getWidth = () => filtersRef.current.offsetWidth;

	let sortOptions = [ "New In", "Popularity" , "Price High" , "Price Low" ]

	const { globalSort, setGlobalSort } = useContext( CategoryContext )
	const [ filtersActive, setFiltersActive ] = useState()
	const [ sortActive, setSortActive ] = useState()
	const [ sortName, setSortName ] = useState( globalSort.label )
	const [ width, setWidth ] = useState( 0 )
	const { setSort, filterState, setFilterState, subCategories } = props

	useEffect( () => {

		if ( filtersRef ) {
			setWidth( filtersRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};

	}, [] );

	const sortBy = query => {
		switch ( query ) {
			case 'New In':
				setSort( 'created_at:DESC' )
				setGlobalSort( { label: query, sort: 'created_at:DESC' } )
				break;
			case 'Popularity':
				setSort( 'quantitySold:DESC' )
				setGlobalSort( { label: query, sort: 'quantitySold:DESC' } )
				break;
			case 'Price High':
				setSort( 'price:DESC' )
				setGlobalSort( { label: query, sort: 'price:DESC' } )
				break;
			case 'Price Low':
				setSort( 'price:ASC' )
				setGlobalSort( { label: query, sort: 'price:ASC' } )
				break;
			default:
				break;
		}
	}

	const handleChangeSort =  ( e ) => {
		const query = e.target.value
		sortBy( query )
		setSortName( query )
		return
	}

	return (
		<div className="filters" ref={ filtersRef }>

			<div className="filters__header">

				<div className="filters__filters-toggle-container">
					<button className={`filters__filter-toggle ${ filtersActive ? 'active' : '' }`} onClick={ () => setFiltersActive( !filtersActive ) }>
						Filter
						<Chevron className={`filters__filter-toggle-icon ${ filtersActive ? 'active' : '' }`} />
						{ filtersActive &&
							<span className="filters__filter-toggle-close">x</span>
						}
					</button>
				</div>

				<div className="filters__sort-container">
					{ width > MEASUREMENTS.PHABLET ? (
						<>
							<div className="filters__sort-label">Sort:</div>
							<ul className="filters__sort-list" >
								{ sortOptions.map( option => {
									return (
										<li
											key={ option }
											className={`filters__sort-item ${option === sortName ? 'active' : ''}`}>
											<button
												className='filters__sort-item-button'
												value={ option }
												data-tag-name={ option.name }
												onClick = { e => handleChangeSort( e ) }>
													{ option }
											</button>
										</li>
									)
								} ) }
							</ul>
						</>
					) : (
						<button className={`filters__filter-toggle filters__filter-toggle--sort ${ sortActive ? 'active' : '' }`} onClick={ () => setSortActive( !sortActive ) }>
							Sort
							<Chevron className={`filters__filter-toggle-icon ${ sortActive ? 'active' : '' }`} />
							{ sortActive &&
								<span className="filters__filter-toggle-close">x</span>
							}
						</button>
					)}
				</div>
			</div>

			<div className={`filters__filters-container ${ filtersActive ? 'active' : '' }`}>
				<FilterList subCategories={ subCategories } filterState={ filterState } setFilterState={ setFilterState } />
			</div>

			{ width <= MEASUREMENTS.PHABLET &&
				<div className={`filters__filters-container ${ sortActive ? 'active': '' }`}>
					<div className="filters__filters-wrapper">
						<ul className="filters__sort-list" >
							{ sortOptions.map( option => {
								return (
									<li
										key={ option }
										className={`filters__sort-item ${option === sortName ? 'active' : ''}`}>
										<button
											className='filters__sort-item-button'
											value={ option }
											data-tag-name={ option.name }
											onClick = { e => handleChangeSort( e ) }>
												{ option }
												{ option === sortName &&
													<span className="filters__filter-item-close">x</span>
												}
										</button>
									</li>
								)
							} ) }
						</ul>
					</div>
				</div>
			}
		</div>
	)
}